<template>
  <v-container>
    <v-card>
      <v-card-title>Abonniere alle Themen, die dich interessieren</v-card-title>
      <v-card-text class="mx-5">
        <v-checkbox
          v-for="tag in tags" :key="tag.id"
          v-model="tagIds"
          :value="tag.id"
          :label="tag.name"
          :hint="tag.desc"
          multiple
          persistent-hint
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" :disabled="!dirty" :loading="saving" class="my-5" @click="save">Speichern</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'TagsView',
  data () {
    return {
      tagIds: [],
      saving: false
    }
  },
  computed: {
    tags () {
      return Object.keys(this.$root.tags || [])
        .map(tagId => ({ id: tagId, ...this.$root.tags[tagId] }))
        .sort((a, b) => a.name < b.name ? -1 : 1)
    },
    dirty () {
      return [...this.tagIds].sort().join('-') !== [...(this.$root.user.tagIds || [])].sort().join('-')
    }
  },
  methods: {
    async save () {
      this.saving = true
      await this.$fb.db.doc('users/' + this.$root.userId).update({
        tagIds: this.tagIds,
        showTagsInfo: false
      })
      this.saving = false
      this.$emit('close')
    }
  },
  created () {
    this.tagIds = [...this.$root.user.tagIds || []] || []
  }
}
</script>
